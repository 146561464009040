import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/styles/main.scss";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { DictionaryProvider } from "../apis/dictionaryContext";
import { SettingsProvider } from "../apis/settingsContext";
import Cookies from "../components/Cookies";
import classNames from "classnames";

const MainLayout = ({ children, pageContext }) => {
  const errorPage = pageContext.layout === "404";
  const sitemap = pageContext.layout === "sitemap";
  const dark = pageContext.layout === "dark";
  if (sitemap) {
    if (typeof document !== "undefined") {
      document.body.classList.add("pt-0");
    }
  } else {
    if (typeof document !== "undefined") {
      document.body.classList.remove("pt-0");
    }
  }
  return (
    <>
      <Cookies />
      <SettingsProvider>
        <DictionaryProvider>
          {!sitemap && <Navigation dark={dark} />}
          <main className={classNames(dark && "pt-0")}>{children}</main>
          {!errorPage && <>{!sitemap && <Footer dark={dark} />}</>}
        </DictionaryProvider>
      </SettingsProvider>
    </>
  );
};

export default MainLayout;
