import React from "react";
import Right from "../assets/images/right.svg";
import classnames from "classnames";
import { Link } from "gatsby-plugin-react-i18next";

const ButtonLink = ({ href, children, reversed, noarrow }) => {
  return (
    <Link
      to={href}
      className={classnames({
        buttonLink: true,
        reversed: reversed,
      })}
    >
      {children}
      {!noarrow && <Right />}
    </Link>
  );
};

export default ButtonLink;

export const ButtonStyled = ({
  type,
  fullWidth,
  children,
  reversed,
  noarrow,
  bordered,
  isLoading,
  onClick,
}) => {
  return (
    <button
      type={type}
      className={classnames({
        buttonLink: true,
        reversed: reversed,
        "w-100": fullWidth,
        bordered: bordered,
      })}
      onClick={onClick}
      disabled={isLoading}
    >
      {children}
      {!noarrow && <Right />}
    </button>
  );
};
