import React, { useContext, useRef } from "react";
import { routes } from "../config/routes";
import Logo from "../assets/images/logo.svg";
import LogoDark from "../assets/images/LogoDark.svg";
import Pl from "../assets/images/poland.svg";
import Eng from "../assets/images/uk.svg";
import Hamburger from "./Hamburger";
import { Container } from "react-bootstrap";
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import { DictionaryContext } from "../apis/dictionaryContext";
import Cookies from "js-cookie";
import classNames from "classnames";
import Right from "../assets/images/right.svg";
import { arrayParse } from "../config/parse";
import { graphql, useStaticQuery } from "gatsby";
import slugify from "slugify";
import ArrowRight from "../assets/images/arrowRight.svg";
import { useTranslation } from "react-i18next";

const query = graphql`
  query ServicesToNavigationQuery {
    allStrapiService(sort: { order: ASC }, filter: { order: { ne: 999 } }) {
      nodes {
        service_title
        order
        locale
        icon_render
        navigation_section
        localizations {
          data {
            attributes {
              locale
              service_title
              icon_render
              order
              navigation_section
            }
          }
        }
      }
    }
  }
`;

const Navigation = ({ dark }) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openSubmenu, setOpenSubmenu] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const { language, originalPath } = useI18next();
  const dictionary = useContext(DictionaryContext);
  const plRef = React.useRef();
  const enRef = React.useRef();
  const { t } = useTranslation();

  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarOnScroll);
  }, []);

  React.useEffect(() => {
    const rememberLng = Cookies.get("n7mobile.language.remember");
    if (rememberLng) {
      if (rememberLng === "en" && language !== "en") {
        enRef?.current.click();
      }
      if (rememberLng === "pl" && language !== "pl") {
        plRef?.current.click();
      }
    } else {
      if (navigator && navigator.language === "pl-PL" && language !== "pl") {
        plRef?.current.click();
      }
    }
  }, []);

  const changeNavbarOnScroll = () => {
    setOpenMenu(false);
    if (window.scrollY > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const layerMenuClose = () => {
    setOpenMenu(false);
  };

  const { allStrapiService } = useStaticQuery(query);
  const filteredServices = arrayParse(
    allStrapiService.nodes.filter((item) => item.locale === language),
    ["order", "navigation_section"],
  );
  const headerRef = useRef();

  return (
    <header
      ref={headerRef}
      className={classNames(
        scrolled ? "Header scrolled" : "Header",
        dark && "dark",
      )}
    >
      <div
        className={openMenu ? "menuLayer active" : "menuLayer"}
        onClick={layerMenuClose}
        role="none"
      />
      <Container>
        <div className="Header__wrapper">
          <Link to={routes.homepage} className="logoLink">
            {dark ? <LogoDark /> : <Logo />}
          </Link>
          <div
            className={openMenu ? "mobileContainer active" : "mobileContainer"}
          >
            <nav className="Navigation">
              <ul className="Navigation__list">
                <li
                  className="Navigation__list-item"
                  onMouseEnter={() => {
                    headerRef.current.classList.add("blackColor");
                  }}
                  onMouseLeave={() => {
                    headerRef.current.classList.remove("blackColor");
                  }}
                >
                  <Link
                    to={routes.services}
                    activeClassName={"active"}
                    partiallyActive={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setOpenSubmenu(!openSubmenu);
                    }}
                  >
                    {dictionary.services[language]}
                    <Right />
                  </Link>

                  <div
                    className={classNames("submenu", !!openSubmenu && "active")}
                  >
                    <Container>
                      <div className="linkColumn br">
                        <Link
                          to={routes.services}
                          activeClassName={"active"}
                          onClick={() => setOpenMenu(false)}
                          className={
                            "submenu-link title text-decoration-underline"
                          }
                        >
                          {language === "pl"
                            ? "Wszystkie usługi"
                            : "All services"}
                        </Link>
                      </div>
                      <div className="linkColumn">
                        <p className="title">Development</p>
                        {/*<Link*/}
                        {/*  className={"submenu-link"}*/}
                        {/*  activeClassName={"active"}*/}
                        {/*  to={`/ott-development`}*/}
                        {/*  onClick={() => setOpenMenu(false)}*/}
                        {/*>*/}
                        {/*  OTT Development*/}
                        {/*  <ArrowRight />*/}
                        {/*</Link>*/}
                        {filteredServices.map((service) => (
                          <>
                            {service[language].navigation_section ===
                              "Development" && (
                              <Link
                                onClick={() => setOpenMenu(false)}
                                key={service[language].service_title}
                                className={"submenu-link"}
                                activeClassName={
                                  service[language].icon_render === "tv"
                                    ? ""
                                    : "active"
                                }
                                to={
                                  service[language].icon_render === "tv"
                                    ? `${routes.ott}`
                                    : `/services/${slugify(service["en"].service_title, { lower: true })}`
                                }
                              >
                                {service[language].service_title}
                                <ArrowRight />
                              </Link>
                            )}
                          </>
                        ))}
                      </div>
                      <div className="linkColumn">
                        <p className="title">Design</p>
                        {filteredServices.map((service) => (
                          <>
                            {service[language].navigation_section ===
                              "Design" && (
                              <Link
                                key={service[language].service_title}
                                className={"submenu-link"}
                                activeClassName={"active"}
                                onClick={() => setOpenMenu(false)}
                                to={`/services/${slugify(service["en"].service_title, { lower: true })}`}
                              >
                                {service[language].service_title}
                                <ArrowRight />
                              </Link>
                            )}
                          </>
                        ))}
                      </div>
                    </Container>
                  </div>
                </li>
                <li className="Navigation__list-item">
                  <Link
                    to={routes.projects}
                    onClick={() => setOpenMenu(false)}
                    activeClassName={"active"}
                    partiallyActive={true}
                  >
                    {dictionary.projects_and_clients[language]}
                  </Link>
                </li>
                <li className="Navigation__list-item">
                  <Link
                    to={routes.about}
                    onClick={() => setOpenMenu(false)}
                    activeClassName={"active"}
                  >
                    {dictionary.about_us[language]}
                  </Link>
                </li>
                <li className="Navigation__list-item">
                  <Link
                    to={routes.career}
                    activeClassName={"active"}
                    onClick={() => setOpenMenu(false)}
                  >
                    {dictionary.career[language]}
                  </Link>
                </li>
                <li className="Navigation__list-item">
                  <Link
                    to={routes.blog}
                    activeClassName={"active"}
                    partiallyActive={true}
                    onClick={() => setOpenMenu(false)}
                  >
                    {dictionary.blog[language]}
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="LanguagesSelector">
              <ul className="LanguageList">
                <li className="LanguageList__item">
                  <Link
                    ref={plRef}
                    className="lngButton"
                    to={originalPath}
                    language={"pl"}
                    onClick={() =>
                      Cookies.set("n7mobile.language.remember", "pl", {
                        expires: 365,
                      })
                    }
                  >
                    <Pl />
                  </Link>
                </li>
                <li className="LanguageList__item">
                  <Link
                    ref={enRef}
                    className="lngButton"
                    to={originalPath}
                    language={"en"}
                    onClick={() =>
                      Cookies.set("n7mobile.language.remember", "en", {
                        expires: 365,
                      })
                    }
                  >
                    <Eng />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="contactButton">
              <Link
                to={routes.contact}
                className={"buttonLink"}
                onClick={() => setOpenMenu(false)}
              >
                <span> {dictionary.contact[language]}</span>
              </Link>
            </div>
          </div>
          <Hamburger isMenuOpened={openMenu} setIsMenuOpened={setOpenMenu} />
        </div>
      </Container>
    </header>
  );
};

export default Navigation;
