import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const SettingsContext = React.createContext(null);

const query = graphql`
  query SettingsQuery {
    strapiSettings {
      blog_posts_per_page
      contact_email_address
      jobs_email_address
    }
  }
`;

export const SettingsProvider = ({ children }) => {
  const { strapiSettings } = useStaticQuery(query);
  return (
    <>
      <SettingsContext.Provider value={strapiSettings}>
        {children}
      </SettingsContext.Provider>
    </>
  );
};
