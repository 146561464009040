import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Paragraph from "./Paragraph";
import { ButtonStyled } from "./ButtonLink";
import { useI18next } from "gatsby-plugin-react-i18next";
import Cookies from "js-cookie";

const query = graphql`
  query CookieImage {
    allFile(filter: { name: { eq: "n7cookies" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            webpOptions: { quality: 90 }
            quality: 90
            placeholder: BLURRED
            blurredOptions: { width: 100 }
          )
        }
      }
    }
  }
`;
const CookiesInfo = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(query);
  const image = nodes[0];
  const { language } = useI18next();
  const info = React.useRef();
  const closeWithCookie = () => {
    Cookies.set("n7Mobile.com-cookies-info", "true", { expires: 365 });
    info.current.classList.remove("active");
  };
  const closeWithoutCookie = () => {
    info.current.classList.remove("active");
  };
  React.useEffect(() => {
    setTimeout(() => {
      if (!Cookies.get("n7Mobile.com-cookies-info")) {
        info.current.classList.add("active");
      }
    }, 2000);
  }, [info]);

  return (
    <div className="cookiesInformation" ref={info}>
      <div className="container">
        <div className="cookieImage">
          <GatsbyImage alt="cookies" image={getImage(image.childImageSharp)} />
        </div>
        <Paragraph className="paragraph" muted semiBold small>
          {language === "pl"
            ? "Ta strona korzysta z plików cookie oraz Google Analytics, aby\n" +
              "          dostarczyć nam (w pełni anonimowych) statystyk. Dane, które są w\n" +
              "          formularzu kontaktowym, używamy tylko i wyłącznie w celu\n" +
              "          skontaktowania się z Tobą."
            : "This website is using cookies and Google Analytics for collecting fully anonymous statistics. Your credentials, that we need to provide us in a contact form, are used only for purposes of replying you after."}{" "}
          <Link to="/privacy-policy">
            {language === "pl" ? (
              <>Dowiedz&nbsp;się&nbsp;więcej</>
            ) : (
              <>Learn&nbsp;more</>
            )}
          </Link>
        </Paragraph>
        <div className="buttons">
          <ButtonStyled noarrow onClick={closeWithCookie}>
            {language === "pl" ? "Akceptuję" : "Accept"}
          </ButtonStyled>
          <ButtonStyled noarrow reversed bordered onClick={closeWithoutCookie}>
            {language === "pl" ? "Odrzucam" : "Reject"}
          </ButtonStyled>
        </div>
      </div>
    </div>
  );
};

export default CookiesInfo;
