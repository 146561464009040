import React from "react";

const Hamburger = ({ setIsMenuOpened, isMenuOpened }) => {
  const menuOpenHandler = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <div className="d-flex d-lg-none ms-auto">
      <button
        onClick={menuOpenHandler}
        className={
          !isMenuOpened
            ? `hamburger hamburger--collapse`
            : `hamburger hamburger--collapse is-active`
        }
        type="button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    </div>
  );
};

export default Hamburger;
