export const routes = {
  homepage: "/",
  services: "/services",
  servicesDetails: "/service",
  projects: "/projects",
  project: "/project",
  about: "/about-us",
  career: "/career",
  blog: "/blog",
  blogDetails: "/news",
  contact: "/contact",
  privacyPolicy: "/privacy-policy",
  siteMap: "/sitemap",
  ott: "/ott-development",
};
