import React from "react";
import classnames from "classnames";

const LabelText = ({ tag = "h2", children }) => {
  const Tag = `${tag}`;
  return (
    <Tag
      className={classnames({
        labelText: true,
      })}
    >
      {children}
    </Tag>
  );
};

export default LabelText;
