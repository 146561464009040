import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import Right from "../assets/images/right.svg";
import Paragraph from "./Paragraph";
import classnames from "classnames";

const FooterLink = ({ to, gray, children, activeClassName }) => {
  return (
    <div
      className={classnames({
        footerLink: true,
        "gray-link": gray,
      })}
    >
      <Link to={to} activeClassName={activeClassName}>
        <Right />
        <Paragraph tag="span" gray={gray}>
          {children}
        </Paragraph>
      </Link>
    </div>
  );
};

export default FooterLink;
