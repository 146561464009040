import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { localizationsParseArray } from "../config/parse";

export const DictionaryContext = React.createContext(null);

const query = graphql`
  query DirectoryQuery {
    allStrapiDictionary {
      nodes {
        key
        locale
        value
        localizations {
          data {
            attributes {
              key
              locale
              value
            }
          }
        }
      }
    }
  }
`;

export const DictionaryProvider = ({ children }) => {
  const { allStrapiDictionary } = useStaticQuery(query);
  const data = localizationsParseArray(allStrapiDictionary.nodes);
  return (
    <>
      <DictionaryContext.Provider value={data}>
        {children}
      </DictionaryContext.Provider>
    </>
  );
};
