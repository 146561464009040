import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import Paragraph from "./Paragraph";
import FooterLink from "./FooterLink";
import { routes } from "../config/routes";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import Logo from "../assets/images/logo-transparent.svg";
import Fb from "../assets/images/fb.svg";
import In from "../assets/images/in.svg";
import Globe from "../assets/images/globe.svg";
import LabelText from "./LabelText";
import { DictionaryContext } from "../apis/dictionaryContext";
import { graphql, useStaticQuery } from "gatsby";
import { arrayParse, localizationsParse } from "../config/parse";
import slugify from "slugify";
import parse from "html-react-parser";
import classNames from "classnames";

const query = graphql`
  query FooterQuery {
    allStrapiProject(
      filter: { in_footer: { eq: true }, publishedAt: { ne: null } }
    ) {
      nodes {
        project_title
        locale
        withDetails
        localizations {
          data {
            attributes {
              locale
              project_title
              withDetails
            }
          }
        }
      }
    }
    strapiFooter {
      KRS
      NIP
      DUNS
      address
      localizations {
        data {
          attributes {
            main_text
            locale
            linkedIn
            facebook
            dribbble
            address
            NIP
            KRS
            DUNS
          }
        }
      }
      main_text
      locale
      facebook
      dribbble
      linkedIn
    }
  }
`;

const Footer = ({ dark }) => {
  const data = useContext(DictionaryContext);
  const { language } = useI18next();
  const { strapiFooter, allStrapiProject } = useStaticQuery(query);
  const footerObject = localizationsParse(strapiFooter);
  const projects = arrayParse(
    allStrapiProject.nodes.filter((el) => el.locale === language),
  );
  return (
    <footer className={classNames({ Footer: true, dark: dark ?? false })}>
      <div className="Footer__top">
        <Container>
          <div className="Top">
            <div className="Top__info">
              <p className="Logo">
                <Link to={routes.homepage}>
                  <Logo />
                </Link>
              </p>
              <Paragraph gray>{footerObject[language].main_text}</Paragraph>
              <div className="SocialsContainer">
                <ul className="List SocialsContainer__list">
                  <li className="List__item">
                    <a
                      href={footerObject[language].facebook}
                      target={"__blank"}
                    >
                      <Fb />
                    </a>
                  </li>
                  <li className="List__item">
                    <a
                      href={footerObject[language].linkedIn}
                      target={"__blank"}
                    >
                      <In />
                    </a>
                  </li>
                  <li className="List__item">
                    <a
                      href={footerObject[language].dribbble}
                      target={"__blank"}
                    >
                      <Globe />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Top__company">
              <LabelText>{data.footerCompanyHeader[language]}</LabelText>
              <ul className="footerList">
                <li>
                  <FooterLink
                    gray
                    to={routes.services}
                    activeClassName={"active"}
                  >
                    {data.services[language]}
                  </FooterLink>
                </li>
                <li>
                  <FooterLink
                    gray
                    to={routes.projects}
                    activeClassName={"active"}
                  >
                    {data.projects_and_clients[language]}
                  </FooterLink>
                </li>
                <li>
                  <FooterLink gray to={routes.about} activeClassName={"active"}>
                    {data.about_us[language]}
                  </FooterLink>
                </li>
                <li>
                  <FooterLink
                    gray
                    to={routes.career}
                    activeClassName={"active"}
                  >
                    {data.career[language]}
                  </FooterLink>
                </li>
                <li>
                  <FooterLink
                    gray
                    to={routes.blog}
                    activeClassName={"active"}
                    partiallyActive={true}
                  >
                    {data.blog[language]}
                  </FooterLink>
                </li>
                <li>
                  <FooterLink
                    gray
                    to={routes.contact}
                    activeClassName={"active"}
                  >
                    {data.contact[language]}
                  </FooterLink>
                </li>
              </ul>
            </div>
            <div className="Top__company">
              <LabelText> {data.footerProjectsHeader[language]}</LabelText>
              <ul className="footerList">
                {projects.map((obj, index) => {
                  if (!obj[language].withDetails) return <></>;
                  return (
                    <>
                      <li key={index}>
                        <FooterLink
                          gray
                          to={
                            routes.projects +
                            "/" +
                            slugify(
                              obj.en
                                ? obj.en.project_title
                                : obj.pl.project_title,
                              { lower: true },
                            )
                          }
                        >
                          {obj[language].project_title}
                        </FooterLink>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
            <div className="Top__contact Contact">
              <div className="name">
                <LabelText>
                  {data.footerN7Mobile[language].replaceAll(" ", "\u00a0")}
                </LabelText>
              </div>
              <div className="Contact__grid">
                <Paragraph gray>
                  {parse(
                    footerObject[language].address.replaceAll("\n", "<br />"),
                  )}
                </Paragraph>
                <Paragraph gray>
                  NIP:&nbsp;{footerObject[language].NIP} <br />
                  D-U-N-S:&nbsp;{footerObject[language].DUNS} <br />
                  KRS:&nbsp;{footerObject[language].KRS} <br />
                </Paragraph>
              </div>
              <div className="Contact__bottom"></div>
            </div>
          </div>
        </Container>
      </div>
      <div className="Footer__bottom Bottom">
        <Container>
          <div className="Bottom__top">
            <FooterLink to={routes.privacyPolicy}>
              {data.privacy_policy[language]}
            </FooterLink>
            <FooterLink to={routes.siteMap}>
              {data.siteMap[language]}
            </FooterLink>
            <Paragraph>{data.copyrights[language]}</Paragraph>
          </div>
          <Paragraph gray>{data.copyrightsText[language]}</Paragraph>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
