export const dataParse = (object) => {
  if (!object) return object;
  const newObj = object;
  Object.keys(object).forEach(function (key, index) {
    if (
      newObj[key] !== null &&
      typeof newObj[key] === "object" &&
      newObj[key]["data"]
    ) {
      newObj[key] = newObj[key].data[key];
    }
  });
  return newObj;
};

export const localizationsParse = (object, similarKeys = []) => {
  const newObject = {};
  newObject[object.locale] = object;
  similarKeys.forEach((key) => {
    if (object[key]) {
      newObject[key] = object[key];
    }
  });
  Object.keys(newObject[object.locale]).forEach(function (key, index) {
    if (
      typeof newObject[object.locale][key] === "object" &&
      newObject[object.locale][key] !== null &&
      key !== "localizations" &&
      !newObject[object.locale][key].localFile
    ) {
      newObject[object.locale][key] = newObject[object.locale][key].data[key];
    }
  });
  object.localizations.data.forEach((local) => {
    newObject[local.attributes.locale] = local.attributes;
  });
  return newObject;
};

export const localizationsParseArray = (array) => {
  const obj = {};
  array.forEach((el) => {
    const element = {};
    element[el.locale] = el.value;
    el.localizations.data.forEach((local) => {
      element[local.attributes.locale] = local.attributes.value;
    });
    obj[el.key] = element;
  });
  return obj;
};

export const arrayParse = (array, similarKeys = [], internalKeys = []) => {
  const newArray = [];
  array.forEach((el) => {
    const element = {};
    element[el.locale] = el;
    similarKeys.forEach((key) => {
      if (el[key]) {
        element[key] = el[key];
      }
    });
    internalKeys.forEach((key) => {
      if (el[key]) {
        element[key] = localizationsParse(el[key]);
      }
    });
    if (el.localizations.data.length < 1) {
      element.pl = el;
    } else {
      el.localizations.data.forEach((local) => {
        element[local.attributes.locale] = local.attributes;
      });
    }
    if (el.localizations?.data[0]?.id) {
      element[el.localizations.data[0].attributes.locale]["strapi_id"] =
        el.localizations.data[0].id;
    }

    Object.keys(el).forEach(function (key, index) {
      if (
        typeof el[key] === "object" &&
        el[key] !== null &&
        key !== "localizations" &&
        key !== "category" &&
        !el[key].localFile
      ) {
        // if (el["localizations"]) {
        //   return;
        // }
        if (el[key]?.data?.[key]) {
          element[el.locale][key] = el[key].data[key];
        }
      }
    });

    newArray.push(element);
  });
  return newArray;
};
